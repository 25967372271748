<template>
	<div class="Address">
		
		<div class="Top">
			<div class="Left">
				<el-button type="primary" icon="el-icon-plus" @click="$Jump('/my/address/info/add')">新增收货地址</el-button>
			</div>
			<div class="Right">
				<span>
					您已创建{{Total}}个地址,最多支持20个
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One" v-for="item in AddressList" :key="item.Id">
				<li>
					<span>所在地区</span>
					<em>{{item.Province}}{{item.City}}{{item.Region}}{{item.Town}}</em>
					<label style="font-size: 12px;color: #999999;">创建于{{item.CreatedAt}}</label>
				</li>
				<li>
					<span>详细地址</span>
					<em>{{item.Town ? Town:''}}{{item.Detail}}</em>
				</li>
				<li>
					<span>手机</span>
					<em>{{item.Mobile}}</em>
				</li>	
				<li>
					<span>联系人</span>
					<em>{{item.Addressee}}</em>
				</li>	
				<li>
					<span>当前状态</span>
					<em>
						{{item.StatusName}} 
						<template v-if="item.Status == 50">
						- {{item.IsDefaultName}}
						</template>
					</em>
					<i>
						<el-button size="mini" @click="$Jump('/my/address/info/'+item.Id)" type="primary">编辑</el-button>
						<el-button v-if="item.Status > 10" size="mini" @click="UpdateAddress(item.Id,10)" type="danger">停用</el-button>
						<el-button v-if="item.Status == 10" size="mini" @click="UpdateAddress(item.Id,50)" type="success">启用</el-button>
						<el-button v-if="item.IsDefault == 1" size="mini" @click="UpdateAddress(item.Id,2)" type="danger">关闭默认</el-button>
						<el-button v-if="item.IsDefault == 2" size="mini" @click="UpdateAddress(item.Id,1)" type="success">启用默认</el-button>
						<el-button size="mini" @click="Del(item.Id,-1)" type="info">删除</el-button>
					</i>
				</li>	
			</div>
			
		</div>
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="Total" :page-size="PageSize" :current-page="Page" @current-change="handleCurrentChange">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination} from 'element-ui'
	export default {
	  name: 'AddressList',
	  props: {
	  },
	  data() {
	      return {
			  AddressList:[],
			  Page:1,
			  PageSize:8,
			  Total:0
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination
	  },
	  created() {
	  	this.GetList(1)
	  },
	  methods:{
		  handleCurrentChange(val){
		  			 this.GetList(val)
		  },
		  Del(_id){
		  			  this.$confirm('此操作将删除, 是否继续?', '提示', {
		  			  	confirmButtonText: '确定',
		  			  	cancelButtonText: '取消',
		  			  	type: 'warning'
		  			    }).then(() => {
		  					this.UpdateAddress(_id,-1)
		  			    })
		  },
		  GetList(_page){
		  
		  		let data = {
		  			Service:'Address',
		  			Class: 'Address',
		  			Action: 'List',
		  			Page:_page,
		  			PageSize:this.PageSize,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  					this.AddressList = res.Data.AddressList
		  					this.Page = res.Data.CurrentPage
		  					this.Total = res.Data.Total
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  UpdateAddress(_id,_status){
		  
		  	let data = {
		  		Service:'Address',
		  		Class: 'Address',
		  		Action: 'Update',
		  		Id:_id,
		  		Status:_status,
		  	}
		  			if(_status == -1){
		  				data.Action = 'Del'
		  			}else if(_status == 1){
		  				data.IsDefault = 1
		  				delete data.Status
		  			}else if(_status == 2){
		  				data.IsDefault = 2
		  				delete data.Status
		  			}
		  	
		  	this.$post(this.$store.getters.getApiHost,data)
		  	.then((res) => {
		  		
		  		if(res.ErrorId != 0){
		  			this.$message(res.Msg)
		  			return
		  		}
		  		if(_status == 50){
		  					this.$message('启用成功')
		  				}else if(_status == 10){
		  					this.$message('停用成功')
		  				}else if(_status == 2){
		  					this.$message('停用默认成功')
		  				}else if(_status == 1){
		  					this.$message('启用默认成功')
		  				}else if(_status == -1){
		  					this.$message('删除成功')
		  				}
		  		this.GetList()
		  		
		  	})
		  	
		  }
	  }
	}
</script>

<style scoped>
.Address{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.Address .Top{
	display: flex;
	align-items: center;
}
.Address .Top .Left{
	
}
.Address .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.Address .List{
	margin-top: 20px;	
}
.Address .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.Address .List .One:hover{
	background-color: rgba(228,0,0,0.1);
}
.Address .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.Address .List .One li span{
	width: 100px;
	color: rgba(0,0,0,0.3);
}
.Address .List .One li  em{
	flex: 1;
}
.Address .List .One li i{
	
}
</style>
